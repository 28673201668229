.navbar {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 1.8rem;
  font-size: 1.5rem;
}

.nav-menu {
  width: 400px;
  height: 100vh; /* Cover the full viewport height */
  position: fixed;
  top: 0px;
  left: -400px;
  transition: left 250ms ease-in-out;
  display: flex;
  align-items: center;
  z-index: 1002;
  background-color: #fafafa;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

@media (max-width: 768px) {
  .nav-menu {
    width: 100%;
    left: -100%;
    justify-content: center;
  }
}

.nav-menu.active {
  left: 0;
}

.nav-text {
  display: flex;
  align-items: center;
  padding: 8px 0px 2px 16px;
  height: 55px;
  opacity: 0;
  transition: opacity 0.75s ease-in-out; /* Increased transition duration */
}

.nav-menu.active .nav-text {
  opacity: 1;
}

.nav-text a {
  color: #4a4a4a;
  font-size: 18px;
  width: 95%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.nav-text a:hover {
  /* color: #fafafa; */
  transition: 0.4s ease-in-out;
  color: #e72e7c;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 0px;
  display: flex;
  position: absolute;
  top: 40px;
  left: 0px;
}

span {
  margin-left: 16px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);  
  z-index: 1001;
  display: none;
}

.overlay.active {
  display: block;
}
