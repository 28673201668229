/* create class for background colors */
.App {
  text-align: center;
  /* background-color: #f5ece7; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg-maincolor {
  background-color: #141415;
}
.bg-secondcolor {
  background-color: white;
}
.text-pcolor {
  color: black;
}
.image-transition {
  transition: opacity 0.3s ease-in-out;
}